import React from 'react';
import cc from 'classcat';
import styles from './intro.module.sass';
import { Anchor } from '../Text';
// import GlobusIcon from '../../public/icons/globus.svg';
// import ArrowIcon from '../../public/icons/arrow.svg';
import { Icon } from '../Icon';
import { IconSVG } from '../SpriteSVG';
import { isNewYear } from '../../utils/isBlackFridayCheck';

export const Intro = ({ link, seoText }) => (
  <div className={cc({ [styles.introBacground]: isNewYear() })}>
    <div className={styles.intro}>
      <h1 className={styles.introTitle}>Круизы бронируют здесь</h1>
      <div className="visually-hidden">{seoText}</div>
      {link && (
        <Anchor href={link.href} variant="linkAsbutton2">
          <span className={styles.introLink}>
            <Icon
              icon={
                <IconSVG
                  name={'common/globus'}
                  width={20}
                  height={20}
                  title="Иконка глобуса"
                />
              }
              title="Иконка глобуса"
            />
            <span>{link.text}</span>
            <Icon
              icon={
                <IconSVG
                  name={'common/arrow'}
                  width={11}
                  height={10}
                  title="Иконка Стрелочки"
                />
              }
              title="Иконка Стрелочки"
            />
          </span>
        </Anchor>
      )}
    </div>
  </div>
);

Intro.defaultProps = {
  link: undefined,
};
